<template>
  <b-container fluid>
    <b-row>
      <div class="col-12 col-sm-12 col-md-12 col-lg-12">
        <div class="iq-card position-relative inner-page-bg p-3" v-if="myPlan && myPlan.subs_plan_name">
          <div class="">
            <h3 class="primary-color">
              {{myPlan.subs_plan_name}}
            </h3>
            <p class="subscriptionDes text-white">{{ myPlan.currency_symbol }} {{myPlan.subs_plan_price }} / {{myPlan.subs_plan_interval.replace('ly', '')}}</p>
            <p>
              <span v-if="current_ts/1000 > myPlan.subs_end_on">
                Plan Expired On:<b class="ml-2">{{myPlan.subs_end_on | dateFormatDDMMYYYYHHMMA}}</b>
                <button class="ml-2 btn" style="background-color: #109618; color: white" type="button" @click="showConfirmPlan()">Renew</button>
              </span>
              <span v-else>
                Plan Expires On:<b class="ml-2">{{myPlan.subs_end_on | dateFormatDDMMYYYYHHMMA}}</b>
              </span>
              <br>
              <u style="cursor:pointer;ma">
                <span @click="showPlanDetail(myPlan)"><b>View Plan Details</b></span>
              </u>
            </p>
          </div>
        </div>
      </div>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">
              Billing Transactions for&nbsp;<span class="primary-color">{{ gideUserId == userData.user_id ? userData.user_name : user_name }}</span>
            </h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" title="View billing adress" @click="goToUserBillDetailsList">
              <i class="fa fa-map-marker font-size-20"></i>
            </b-button>
          </template>

          <template v-slot:body>
            <b-row>
              <b-col md="12" v-if="payTransactionsList && payTransactionsList.length > 0">
                <div class="table_reaponsive">
                  <b-table-simple striped class="text-left">
                    <thead>
                      <tr>
                        <th v-for="(item,index) in columns" :key="index" :class="item.key === 'item' ? 'text-left' : ''">
                          {{ item.label }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(body, bodyKey) in payTransactionsList" :key="bodyKey">
                        <template  v-for="(item,index) in columns">
                          <th v-if="item.key === 'bank'" :key="item.key+index">
                            {{ item.label }}
                          </th>
                          <td v-if="item.key == 'sno'" :key="item.key + index" class="text-left">
                            <h6 class="mb-0">
                              {{ bodyKey+1 }}
                            </h6>
                          </td>
                          <td v-else-if="item.key != 'action'" :key="item.key + index" class="text-left">
                            <h6 class="mb-0">
                              <span v-if="item.key == 'pay_amount'"> {{ body.currency_symbol }} </span>
                              <template v-if="item.key == 'created_on'">
                                {{ body[item.key] | dateFormat }}
                              </template>
                              <template v-else-if="item.key == 'modified_on'">
                                {{ body[item.key] | dateFormat }}
                              </template>
                              <template v-else>
                                {{ body[item.key] }}
                              </template>
                            </h6>
                            <a class="mb-0 pointer" v-if="item.key == 'subs_plan_name' && body.pay_tid" @click="showPlanDetail(body)">View</a>
                          </td>
                          <td v-if="item.key == 'action'" :key="item.key + index" class="text-left">
                            <b-button variant=" iq-bg-primary" v-if="body.pay_status_code == 0" size="sm" @click="showConfirmPlan(body)">
                              Pay
                            </b-button>
                            <b-button variant="iq-bg-primary" v-if="body.pay_invoice_url" size="sm" @click="openUrl(body.pay_invoice_url)">
                              View Invoice
                            </b-button>
                            <!-- <b-button variant="iq-bg-primary" v-if="body.pay_invoice_url"  size="sm" @click="openUrl(body.pay_invoice_url)">Download</b-button> -->
                            <b-button  v-if="!body.pay_tid" size="sm" >
                              Failed
                            </b-button>
                          </td>
                        </template>
                      </tr>
                    </tbody>
                  </b-table-simple>
                </div>
              </b-col>
              <b-col md="12" v-else>
                No data found
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <!-- Subscription Confirmation Popup -->
    <SubscriptionConfirmation :propShowSCModal="propShowSCModal" :propSubsPlanDetails="propSubsPlanDetails" @emitCloseSCModal="emitCloseSCModal" />

    <!-- PlanDetail -->
    <PlanDetail :propShowPDModal="propShowPDModal" :propSubsPlanDetails="propSubsPlanDetails" @emitClosePDModal="emitClosePDModal" />

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </b-container>
</template>

<script>
import { PayTransactionss } from "../../../FackApi/api/PayTransactions"
import { socialvue } from "../../../config/pluginInit"
import { SubscriptionPlans } from "../../../FackApi/api/Subscription/subscriptionPlan"
import SubscriptionConfirmation from "../../../components/SubscriptionConfirmation.vue"
import PlanDetail from "../../../components/PlanDetail.vue"
import userPermission from "../../../Utils/user_permission"

export default {
  name: "PayTransactionsList",
  components: {
    SubscriptionConfirmation,
    PlanDetail
  },
  data () {
    return {
      apiName: "payTransactions_list",
      cvCardTitle: "Transactions",
      cvAddModalHeader: "Pay Now",
      cvbtnModalCancel: "Cancel",
      cvbtnModalExpand: "Expand",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Transactions List Response",
      propShowSCModal: false,
      propShowPDModal: false,
      columns: [
        { label: "# ", key: "sno", class: "text-left", sortable: true },
        { label: "Plan Id", key: "pay_subs_id", class: "text-left", sortable: true },
        { label: "Plan", key: "subs_plan_name", class: "text-left", sortable: true },
        { label: "Payment Id", key: "pay_tid", class: "text-left", sortable: true },
        { label: "Order Id", key: "pay_order_id", class: "text-left", sortable: true },
        { label: "Amount", key: "pay_amount", class: "text-left", sortable: true },
        { label: "Status ", key: "pay_status_desc", class: "text-left", sortable: true },
        { label: "Billing Date", key: "created_on", class: "text-left", sortable: true },
        { label: "Modified On", key: "modified_on", class: "text-left", sortable: true }
      ],
      payTransactionsList: null,
      payTransactionsEditObj: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      delObj: null,
      propSelectedTransaction: null,
      myPlan: {},
      propSubsPlanDetails: {},
      current_ts: (new Date().getTime()),
      gideUserId: null,
      user_name: ""
    }
  },
  computed: {
    userData () {
      return { ...this.$store.getters["User/userData"] }
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (this.userData.user_role != "USERROLE11114" && !userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  async mounted () {
    socialvue.index()

    this.gideUserId = this.userData.user_id
    if (this.$route.params.user_id && (this.userData.user_role == "USERROLE11111" || this.userData.user_role == "USERROLE11118" || this.userData.user_role == "USERROLE11116")) {
      this.gideUserId = this.$route.params.user_id
    }

    this.getpayTransactionsList(this.gideUserId)

    this.myPlan = await this.$store.getters["Paygw/myPlanState"]
  },
  methods: {
    /**
      * goToUserBillDetailsList
    */
    goToUserBillDetailsList () {
      this.$router.push("/userbilldetails_list")
    },
    /**
     * goToPayment
    */
    async goToPayment (PaymentBody) {
      console.log(PaymentBody)
      const planDetail = await SubscriptionPlans.subscriptionPlanView(this, PaymentBody.pay_subs_id)
      console.log(planDetail)
    },
    /**
    * viewInvoice
    */
    async viewInvoice (bill) {
      await this.$store.commit("Paygw/addInvoicePlanCommit", bill)
      this.$router.push("/invoice")
    },
    /**
     * getSubsModules
     */
    getSubsModules (subsPlan) {
      let subsModules = []
      try {
        subsModules = subsPlan.subs_plan_modules.split(",")
      }
      catch (err) {
        console.log("Exception occurred at getSubsModules() and Exception:", err.message)
      }
      return subsModules
    },
    /**
     * getpayTransactionsList
     */
    async getpayTransactionsList (gideUserId) {
      try {
        let payTransactionsListResp = await PayTransactionss.payUserTransactionsList(this, gideUserId)
        if (payTransactionsListResp.resp_status) {
          this.payTransactionsList = payTransactionsListResp.resp_data.data
          this.totalRows = payTransactionsListResp.resp_data.count

          this.user_name = this.payTransactionsList[0] && this.payTransactionsList[0].user_name ? this.payTransactionsList[0].user_name : "-"
        }
        else {
          this.toastMsg = payTransactionsListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.log("Exception occurred at getpayTransactionsList() and Exception:", err.message)
      }
    },
    /**
     * showConfirmPlan
     */
    async showConfirmPlan (PaymentBody = null) {
      if (PaymentBody) {
        const planDetailResp = await SubscriptionPlans.subscriptionPlanView(this, PaymentBody.pay_subs_id)

        if (!planDetailResp.resp_status) {
          this.toastMsg = "This plan has been expire now please pick one"
          this.$router.push("/subscription_plans")
        }
        this.propShowSCModal = Math.random()
        this.propSubsPlanDetails = planDetailResp.resp_data.data
      }
      else {
        this.$router.push("/subscription_plans")
      }
    },
    /**
     * emitCloseSCModal
     */
    emitCloseSCModal () {
      this.propShowSCModal = false
      this.propSubsPlanDetails = {}
    },
    /**
     * showPlanDetail
     */
    async showPlanDetail (plan) {
      this.propShowPDModal = Math.random()
      this.propSubsPlanDetails = plan
    },
    /**
     * emitCloseSCModal
     */
    emitClosePDModal () {
      this.propShowSCModal = false
      this.propSubsPlanDetails = {}
    },
    /**
     * openUrl
     */
    openUrl (url) {
      window.open(url, "")
    }
  }
}
</script>

<style>
.subscriptionDes{
  margin-bottom: 0px;
  font-size: 13px;
}
.table{
  min-width:700px;
  overflow-x: scroll;
  display: inline-table !important;
}
.table_reaponsive{
  overflow-x: auto;
}
</style>
